import React from "react";
import brand1 from "../images/home/brand1.png";
import brand2 from "../images/home/brand2.png";
import brand3 from "../images/home/brand3.png";
import brand4 from "../images/home/brand4.png";
import brand5 from "../images/home/brand5.png";
import brand6 from "../images/home/brand6.png";
import brand7 from "../images/home/brand7.png";
import brand8 from "../images/home/brand8.png";

const Wecompany=()=>{
    return(
        <>
            <section className="weCompany">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 pt-md-5 pt-sm-0">
                            <h1>We are Trusted by Popular</h1>
                            <h2 className="text-red">Hospitals</h2>
                            <p className="more">
                            In the realm of healing, trust is our foundation, and in the corridors of care, we walk hand in hand with compassion. <br/>Client trust is the currency of our credibility, earned through a symphony of reliability, transparency, and unwavering commitment.<br/><br/>A strong client relationship is nurtured with empathy, sustained by communication, and enriched by a shared journey towards success.</p>
                        </div>
                        <div className="col-sm-4 text-center">
                        
                            <div className="d-flex w-100 pb-4">
                                <div className="w-25">
                                    <a href="#"><img src={brand1} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand2} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand3} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand4} className="img-fluid" alt="Logo" /></a>
                                </div>
                            </div>

                            <div className="d-flex w-100 pb-4">
                                <div className="w-25">
                                    <a href="#"><img src={brand5} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand6} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand7} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand8} className="img-fluid" alt="Logo" /></a>
                                </div>
                            </div>
                            <div className="d-flex w-100 pb-4">
                                <div className="w-25">
                                    <a href="#"><img src={brand1} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand2} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand3} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand4} className="img-fluid" alt="Logo" /></a>
                                </div>
                            </div>
                            <div className="d-flex w-100 pb-4">
                                <div className="w-25">
                                    <a href="#"><img src={brand5} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand6} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand7} className="img-fluid" alt="Logo" /></a>
                                </div>
                                <div className="w-25">
                                    <a href="#"><img src={brand8} className="img-fluid" alt="Logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Wecompany;