import React from "react";
import Header from "./Components/Header";
import Bannerabout from "./Components/Bannerabout";
import Footer from "./Components/Footer";
import Companyoverview from "./Components/Companyoverview";
import Whyus from "./Components/Whyus";
import Team from "./Components/Team";
import Vision from "./Components/Vision";


const About=()=>{
    return(
        <>
        <Header />
        <Bannerabout />
        <Companyoverview />
        <Whyus />
        <Vision />
        <Team />
        <Footer />
        </>
    )
}
export default About;