import React from "react";
import { NavLink } from "react-router-dom";
import imgBulb from "./images/error/bulb.png"
import imgVector from "./images/error/vector.png"

const Notfound=()=>{
    return(
        <>
            <section className="not_found">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="notfound_bg">
                                <h1>404</h1>
                                <img className="bulb" src={imgBulb} alt="Bulb" />
                                <img className="vector" src={imgVector} alt="Vector" />
                            </div>

                            <div className="col-sm-8 mx-auto notfound_content">
                                <h2>Page Not Found</h2>
                                <h3>Hunt & Scouts Management Services
                                </h3>
                                <NavLink to="/" className="btn btn-danger rounded-pill px-5">
                                    Back to Homepage
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Notfound;