import React from "react";
import imgBanner from "../images/about-us/Second-banner.png"

const Companyoverview=()=>{
    return(
        <>
            <section className="overview">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-5 me-3 m-auto">
                            <div className="overImg">
                                <div className="img-card">
                                    <img className="w-100" src={imgBanner} alt="Banner" /></div>
                            </div>
                        </div>
                        <div className="col-sm-6 pt-md-5 mx-auto pt-sm-0">
                            <h1>Our <span className="text-red">Company</span></h1>
                            <p className="pt-3">Hunt & Scouts is a professional Head Hunting and Executive Search company and a sister concern of Crologic Solutions Pvt. Ltd. which provides world class Software and Technology services across the globe.<br/><br/>
                                Hunt & Scouts solves Senior and Mid level recruitment challenges for responsible businesses and purpose-driven organizations on a retained and contingency basis.<br/><br/>
                                Our team of skilled search professionals believe that by headhunting exceptional people into meaningful roles we can help to bring about positive change within Organisations.<br/>
                                We also believe that we have an important role to play in upholding equality of opportunity and in ensuring that our clients can access truly diverse talent.<br/>

</p>
                            <div className="readmores">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Companyoverview;