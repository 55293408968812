import React from 'react';
import Jobportal from '../Jobportal';

const JobPortalContainer = ({data}) => {

    const renderJobPortals = () => {
        return data.map((itemData)=>{
            return <Jobportal {...itemData}/>
        });
    }

    return (
        <>
            {renderJobPortals()}
        </>
    )
}

export default JobPortalContainer;