import React from "react";
import imgBanner from "../images/about-us/Second-banner.png"
import { NavLink } from "react-router-dom";
const Whyus=()=>{
    return(
        <>
            <section className="overview">
                <div className="container-fluid">
                    <div className="row" style={{marginTop:"80px"}}>
                    
                        <div className="col-sm-6 pt-md-5 mx-auto pt-sm-0">
                            <h1 >Why <span className="text-red">Choose us</span></h1>
                            <p className="pt-3">Hunt & Scouts adds value in two distinct way - by providing highly cost-effective recruitment solutions, and by identifying and attracting candidates of the very highest calibre.Our ability to engage with professionals on the upward curve of their career path enables our clients to recruit leaders with the drive and vision to deliver short-term impact and long-term value.The very fundamental differentiator is our belief in hard work, attention to detail, and above all, our ability to deliver.We pride ourselves in our commitment to continually re-invent ourselves to ensure we bring you on-going value added benefits that clearly set us apart.<br/><br/>

Some of the things we do differently include the following: 

<ul>
    <li>We value each candidate as a genuine human being and have reduced our sourcing/hiring biases to almost none.</li>
    <li>We focus on lengthy conversations with candidates so that they are fully aware of the job roles they are going to apply.</li>
    <li>We put dedicated work-force and effort to simplify the job descriptions making them easy to understand both for sourcers and candidates.</li>
    <li>We strike a fine balance between use of technology and human interventions based on the nature of recruiting function.</li>
</ul> 

</p>

                        </div>
                        <div className="col-sm-5 me-3 m-auto">
                            <div className="overImg">
                                <div className="img-card">
                                    <img className="w-100" src={imgBanner} alt="Banner" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Whyus;