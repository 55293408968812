import React from "react";
import { NavLink } from "react-router-dom";
import imgBanner from "../images/about-us/Firs-banner.png";


const Bannerabout=()=>{
    return(
        <>
            <section className="aboutGoal">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 my-auto">
                            <h1>Professional Head Hunting  </h1>
                            <h2 className="text-red">Management advisory Services</h2>
                        </div>
                        <div className="col-sm-6 d-none d-md-block">
                            <img className="w-100" src={imgBanner} alt="Banner" />
                        </div>
                    </div>
                </div>
            </section>
            <nav className="topbreadcrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/about">About us</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Bannerabout;