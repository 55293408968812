import React from "react";
import imgSL1 from "../images/categories/sl-1.png";
import imgSL2 from "../images/categories/sl-2.png"
import imgSL3 from "../images/categories/sl-3.png"

const Servicecategories=()=>{
    return(
        <>
        <div className="proFile">
			<div className="container my-4">	
				<h3 className="text-center pb-5">Services<span className="text-red"> We deal in</span></h3>
				<div id="slideProfile" className="carousel slide">
					<div className="controls-top">
					  <a className="btn-floating prev me-1" data-bs-target="#slideProfile" data-bs-slide="prev" href="#slideProfile">
						<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
					  </a>
					  <a className="btn-floating next"  data-bs-target="#slideProfile" data-bs-slide="next" href="#slideProfile">
						<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
					  </a>
					</div>
				  <div className="carousel-inner">
					<div className="carousel-item active">
						<div className="row">
							<div className="col-md-4">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL1} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">Healthcare Consulting</h4>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-4 clearfix d-none d-md-block">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL2} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">Executive/Contingency Search</h4>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-4 clearfix d-none d-md-block">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL3} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">HR Advisory Serivces</h4>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div className="carousel-item">
						<div className="row">
							<div className="col-md-4">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL1} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">Healthcare Consulting</h4>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-4 clearfix d-none d-md-block">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL2} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">Executive/Contingency Search</h4>
										</div>
									</div>
								</a>
							</div>

							<div className="col-md-4 clearfix d-none d-md-block">
								<a href="job-details.html">
									<div className="card mb-2">
										<div className="jobImage">
											<div className="image-card">
												<img className="card-img-top" src={imgSL3} alt="Profile"/>
											</div>
										</div>
										<div className="card-body rgba-blue">
											<h4 className="text-white">HR Advisory Serivces</h4>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				  </div>
				</div>

	</div>
</div>

        </>
    )
}
export default Servicecategories;