import React from "react";
import { NavLink } from "react-router-dom";

import imgMember4 from "../images/about-us/member4.png"
import imgMember5 from "../images/about-us/member5.png"
import imgMember6 from "../images/about-us/member6.png"


const Team=()=>{
    return(
        <>
            <section className="meetTeam">
                <div className="container-fluid">
                    <h1>Meet Our <span className="text-red">Team</span></h1>
                    <h3>Individually, we are one drop. Together, we are an ocean of possibilities.</h3><br/>
                    
                    <div className="row">
                        <div className="col-sm-3 mb-4">
                            <div className="teams">
                                <a href="javascript:void(0);"><img src={imgMember4} alt="Team" className="teamimage w-100" /></a>
                                <div className="overlay">
                                    <ul className="icons list-inline">
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"></a>
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h3><a href="javascript:void(0);">Navjyoti Jha (MBA, PGDMC)</a></h3>
                            <h4>Principal Consultant & Founder</h4><br/>
                            <div className="col-sm-12 mb-4">
                                <div className="interinner">
                                    <h4>Is a Post Graduate in Mass Com. with total 19 years of experience in Operations, Training , Business Development, Talent Acquisition in Executive Search & Selection Business. Started his career as a Trainer with IBM and then moved to HR and Talent Acquisition with UK & US based technology firms like Boss International and Corlog India; he then moved to consulting space with Healthcare and Pharma hiring with some of the leading search firms like IFAN Global, Lighthouse Partners & Quadrangle Search (Infoedge Iindia ). His last corporate assignment was with a Start-up organization Transhealth India as VP Talent Acquisition. </h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 mb-4">
                            <div className="teams">
                                <a href="javascript:void(0);"><img src={imgMember6} alt="Team" className="teamimage w-100" /></a>
                                <div className="overlay">
                                    <ul className="icons list-inline">
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h3><a href="javascript:void(0);">Neha Gupta</a></h3>
                            <h4>Strategic Consultant</h4><br/>
                            <div className="col-sm-12 mb-4">
                                <div className="interinner">
                                    <h4>Experienced in the entire gamut of operations involved in Business Development, Operations, Project Commissioning, Hospital Business Planning & product development. She started her career with Wockhardt Hospitals and worked with them at several locations all across India. She has also worked with Vidal Healthcare (Formerly TTK Healthcare) Wellness vertical, Cloudnine Hospitals and Motherhood Hospitals. She has also worked as a freelancer on various projects involving Service Excellence training, Quality management in Hospitals, Healthcare Business Analysis, Analysis for process improvement and process mapping. She has rich experience in conducting healthcare feasibility studies for North India.</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 mb-4">
                            <div className="teams">
                                <a href="javascript:void(0);"><img src={imgMember5} alt="Team" className="teamimage w-100" /></a>
                                <div className="overlay">
                                    <ul className="icons list-inline">
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0);"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h3><a href="javascript:void(0);">Sumit Panwar ( B.E )</a></h3>
                            <h4>Senior Consultant</h4><br/>
                            <div className="col-sm-12 mb-4">
                                <div className="interinner">
                                    <h4>Is an Engineering graduate with total 8+ years of experience in HR Industry recruiting IT and Healthcare professionals with exposure to campus, middle and leadership level hiring along with stakeholder management. He started his career with Aspiring Minds Assessments in campus hiring and subsequently moved into executive search space with leading healthcare search firms like Salus Healthcare, IFAN Global and Lighthouse Partners. His last corporate assignment was with WeeBox (A People Strong Venture)</h4>
                                </div>
                            </div>
                        
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
export default Team;