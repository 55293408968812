import React from "react";
import imgBanner from "../images/about-us/Second-banner.png"

const Vision=()=>{
    return(
        <>
        
        
        <section className="valPart">
        <h1 className="meetTeam">Our <span className="text-red">Vision</span></h1>
	<div className="container-fluid">
		<div className="row">
			<div className="col-sm-3">
				<div className="valinner">
                    <i class="fa-solid fa-handshake-simple"></i>
					
					<h3 className="text-red">Partnership</h3>
					<h4>Our values guide our behaviour, decisions, and choices. One of our values is to partnership. We relate to our clients as partners. To work with our clients effectively, we must develop strong relationships based in mutual trust and respect as well as effective collaboration every step of the way.</h4>
				</div>
			</div>
			<div className="col-sm-3">
				<div className="valinner"> 
				                <i class="fa-solid fa-key"></i>
					<h3 className="text-red">Authenticity</h3>
					<h4>Inspires us to genuinely care about our employees, clients and their customers, society, and human kind. Our approach in all that we do is to consider the wider impact.
                    <br/><br/>We are productive and self-motivated, completing our work as efficiently as possible. 
                    </h4>
                    </div>
			</div>
			<div className="col-sm-3">
				<div className="valinner">
					
                    <i class="fa-solid fa-bolt"></i>
					<h3 className="text-red">Integrity</h3>
					<h4>We are committed to our values and we protect our company culture through our commitment to possessing integrity at an organizational level. Carrying out each action with honestly will foster trustworthiness amongst each other, with our clients, and in our dealings with others.</h4>
                    </div>
			</div>
			<div className="col-sm-3">
				<div className="valinner">
					
                    <i class="fa-solid fa-pen"></i>
					<h3 className="text-red">Creativity</h3>
					<h4>We value creativity and innovation at every level. Conventional solutions are simply well-worn paths with a history of success. We believe it's important to understand this history, but to question whether these solutions are always best for us. We seek out new possibilities and solutions to create success.</h4>
                    </div>
			</div>
            
		</div>
	</div>
	<div className="bgnumber"></div>
</section>
            
        </>
    )
}
export default Vision;