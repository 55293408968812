import React from "react";
import Header from './Components/Header';
import './css/stylesheet.css';
import './css/bootstrap.min.css';
import './assets/fonts/fontawesome/css/all.min.css';
import './assets/font/raleway/style.css';
import './assets/font/rubik/style.css';
import Bannerhome from "./Components/Bannerhome";
import Jobportal from "./Components/Jobportal";
import Jobprocess from "./Components/Jobprocess";
import Jobforyou from "./Components/Jobforyou";
import Wecompany from "./Components/Wecompany";
import Footer from "./Components/Footer";
import JobPortalContainer from "./Components/JobPortalComponent";

const Home = () => {
    const dummyData = [
        {
            titleBlack: "",
            titleYellow: "Dummy one",
            desc: " Dummy description one."
        },
        {
            titleBlack: "",
            titleYellow: "Dummy two",
            desc: " Dummy description two."
        },
    ]
    return (
        <>
            <Header />
            <Bannerhome />
            <JobPortalContainer data={dummyData} />
            <Jobportal titleBlack="" titleYellow="Life Sciences & Healthcare" desc=" The healthcare industry is constantly evolving, driven by advances in medical science, technology, and changing demographics. It's a dynamic field that involves collaboration among professionals from various disciplines to ensure the well-being of individuals and communities. <br/>Rapid transformation in the life sciences and health care industry requires firms to strike a balance between patient’s demands, market potential, and policy compliance, while continuing innovation. We offer holistic recruitment solutions that help clients remain competitive in this constantly evolving market." />
            <Jobportal titleBlack="" titleYellow="Consumer Led Business" desc="The core idea of the customer-led company is to focus on needs and not products and H&S helps them build a right team to analyze, plan and implement." />
            <Jobportal titleBlack="" titleYellow="Digital/Technology" desc="Digital/ technologies have risen to prominence as a critical determinant of economic growth, national security, and international competitiveness. It affects everything from resource allocation to income distribution and growth. H&S hunts the right resources for you." />
            <Jobforyou />
            <Wecompany />
            <Footer />
        </>
    )
}

export default Home;