import React, {useState} from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";


const Register=()=>{
    const [user,setUser]=useState({
        firstname:"",lastname:"",email:"",mobile:"",password:"",conpassword:""
    });
    let name,value;
    const handleInputs=(e)=>{
        name=e.target.name;
        value=e.target.value;

        setUser({...user,[name]:value});
    };
    const PostData=(e)=>{
        e.preventDefault();


    };
    return(
        <>
        <Header/>
            <section className="loginForm">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 mx-auto">
                            <form method="post">
                                <h3 className="text-center">REGISTRATION</h3>

                                <div className="latestForm">

                                    <div className="form-group">
                                        <input type="text" name="firstname" id="firstName" className="form-control"  
                                        value={user.firstname}
                                        onChange={handleInputs}
                                        required />
                                        <label className="form-control-placeholder" htmlFor="firstName">First Name</label>
                                        <i className="fa-solid fa-user iconSet"></i>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="lastname" id="lastName" className="form-control"  
                                        value={user.lastname}
                                        onChange={handleInputs} 
                                        required />
                                        <label className="form-control-placeholder" htmlFor="lastName">Last Name</label>
                                        <i className="fa-solid fa-user iconSet"></i>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" name="email" id="email" className="form-control"  
                                        value={user.email}
                                        onChange={handleInputs}
                                        required />
                                        <label className="form-control-placeholder" htmlFor="email">E-mail Address</label>
                                        <i className="fa-regular fa-envelope iconSet"></i>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="mobile" id="mobile" className="form-control"  
                                        value={user.mobile}
                                        onChange={handleInputs}
                                        required />
                                        <label className="form-control-placeholder" htmlFor="mobile">Mobile</label>
                                        <i className="fa fa-mobile iconSet" aria-hidden="true"></i>
                                    </div>

                                    <div className="form-group">
                                        <input type="password" name="password" id="password" className="form-control"  
                                        value={user.password}
                                        onChange={handleInputs}
                                        required />
                                        <label className="form-control-placeholder" htmlFor="password">Password</label>
                                        <i className="fa fa-lock iconSet" aria-hidden="true"></i>
                                    </div>

                                    <div className="form-group">
                                        <input type="password" name="conpassword" id="conpwd" className="form-control"  
                                        value={user.conpassword}
                                        onChange={handleInputs}
                                        required />
                                        <label className="form-control-placeholder" htmlFor="conpwd">Confirm Password</label>
                                        <i className="fa fa-lock iconSet" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <div className="d-grid">
                                    <input type="submit" name="submit" className="btn btn-danger mb-4" id="register" value="Register Now"
                                    onClick={PostData}
                                    />
                                </div>
                                <div className="text-center">
                                    <p>Have't Any Account Yet? <a className="clickHere" href="login.html">Click Here</a></p>
                                    <p>or</p>
                                    <p>Login With Social</p>
                                    <a href="/" className="btn btn-danger btn-floating rounded-pill mx-2">
                                        <i className="fa fa-google me-1"></i> Google
                                    </a>
                                    <a href="/" className="btn btn-primary rounded-pill btn-floating mx-2">
                                        <i className="fa fa-facebook me-1"></i> Facebook
                                    </a>
                                    <a href="/" className="btn btn-info rounded-pill btn-floating mx-2 text-white">
                                        <i className="fa fa-twitter me-1"></i> Twitter
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Register;