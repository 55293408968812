import React from "react";
import Header from "./Components/Header";
import Bannercontact from "./Components/Bannercontact";
import Servicecategories from "./Components/Servicecategories";
import Footer from "./Components/Footer";

const Services=()=>{
    return(
        <>
        <Header />
        <Bannercontact />
        <Servicecategories />
        <Footer />
        </>
    )
}
export default Services;