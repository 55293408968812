import React from "react";

const Jobforyoucard=(props)=>{
    return(
        <>
        <div className="col-sm-3">
							<div className="jobBox">
								<img className="w-100" src={props.imgurl1} alt="Job" />
								<div className="jobBox-content">
									<h3>{props.title}</h3>
									<button type="button" className="btn btn-outline-warning">Apply Now</button>
								</div>
								<div className="jobBox-hover">
									<div className="card">
										<img className="card-img-top" src={props.imgurl2} alt="Job" />
										<div className="jobBox-hover-content">
											<i className="fa fa-cube" aria-hidden="true"></i>
											<h3>{props.title}</h3>	
										</div>
										<div className="card-body">
											<h4>{props.title}	</h4>
											<ul className="list-unstyled mb-0">
												<li>State:- {props.state}</li>
												<li></li>
												<li>Status:- {props.status}</li>
											</ul>
										</div>
										<a href={props.redirect}>Apply Now</a>
									</div>
								</div>
							</div>
						</div>
        </>
    )
}
export default Jobforyoucard;