import React, { useState } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Companyoverview from "./Components/Companyoverview";
import Whyus from "./Components/Whyus";
import Team from "./Components/Team";
import Bannercontact from "./Components/Bannercontact";


const Contact=()=>{
	const [formState,setFormState]=useState({});

	const changeHandler=(event)=>{
		setFormState({...formState,[event.target.name]:event.target.value});
	};

	const submitHandler=(event)=>{
		event.preventDefault();
	}

    return(
        <>
        <Header />
        <Bannercontact />
        <section className="contacts">
	<div className="container">
		<div className="row">	
			<div className="col-sm-4 mb-4">
				<a href="">
					<div className="contInner">
						<i className="fa fa-map" aria-hidden="true"></i>
						<h4>Address</h4>
						<p> 145-B/9, Third Floor, Kishangarh,<br/> Vasant Kunj,
New Delhi 110070 </p>
					</div>
				</a>
			</div>
			<div className="col-sm-4 mb-4">
				<a href="">
					<div className="contInner">
						<i className="fa fa-envelope-open" aria-hidden="true"></i>
						<h4>E-mail</h4>
						<p>connect@huntnscouts.com</p>
					</div>
				</a>
			</div>
			<div className="col-sm-4 mb-4">
				<a href="">
					<div className="contInner">
						<i className="fa fa-phone" aria-hidden="true"></i>
						<h4>Phone Number</h4>
						<p className="f_rubik">+91-1141329664</p>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>

<section className="contactForm">
	<div className="container-fluid">
		<div className="row">
			<div className="col-sm-12">
				<p>Have A Question ?</p> 
				<h3>Get in <span className="text-red">Touch Now?</span></h3>
				
				<form className="form" onSubmit={submitHandler}>
					<div className="row">
						<div className="col-sm-6 form-group">
							<input type="text" name="username" onChange={changeHandler} id="username" className="form-control" placeholder="User Name"   required/>
						</div>
						<div className="col-sm-6 form-group">
							<input type="email" name="email" onChange={changeHandler} id="email" className="form-control" placeholder="Email Address"  required/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 form-group">
							<input type="text" name="subject" onChange={changeHandler} id="subject" className="form-control" placeholder="Subject"   required/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 form-group">
							<input type="text" name="write" onChange={changeHandler} className="form-control" placeholder="Write your Message"   required />
						</div>
					</div>
					<div className="row">
						<div className="mb-3">
							<div className="checkbox">
								<input type="checkbox" name="accept" className="form-check-input" id="accept" required />
								<label className="form-check-label" htmlFor="accept">Accept Term & privacy Policy</label>
							</div>
							  
							<input type="submit" name="submit"  id="sendMsg" className="btn btn-danger rounded-pill" value="Send Message" />
						</div>
					</div>	
				</form>
			</div>	
		</div>
	</div>
</section>

<div className="contactMap">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.787598550812!2d77.16161997458357!3d28.516037489362926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fe6f7338c43%3A0x680a2428706fd33!2sHunt%20%26%20Scouts!5e0!3m2!1sen!2sin!4v1692346595015!5m2!1sen!2sin" height="385" style={{border:'0' , width: '100%' }} allowFullScreen="" aria-hidden="false" tabIndex	="0"></iframe>
</div>
        <Footer />
        </>
    )
}
export default Contact;