import React from "react";
import imgBanner from "../images/contact-us/banner.png"
import { NavLink } from "react-router-dom";

const Bannercontact=()=>{
    return(
        <>
        <section className="nearJob">
	<div className="container-fluid">
		<div className="row">
			<div className="col-sm-5 nearcol">
				<h1>Your dream Job</h1>
				<h2 className="text-red">Is Near to You</h2>
			</div>
			<div className="col-sm-7 d-none d-md-block">
				<div className="row">
					<div className="d-flex w-100">
						<img className="w-100" src={imgBanner} alt="Banner" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<nav className="topbreadcrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/contact">Contact us</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Bannercontact;