import React from "react";
import logo from '../images/logo/Logo.png'

const Footer=()=>{
    return(
        <>
            <div className="intro">
                <div className="way-success"></div>
                <div className="caption">
                    <div className="way-inner">
                        <h3>WAY TO SUCCESS</h3>
                    </div>
                </div>
                <span className="overlay">
                    <svg viewBox="0 0 500 250" enableBackground="new 0 0 500 250" white-space="preserve" preserveAspectRatio="none">
                        <path fill="#282b4a" d="M 250 180.5 c -53.85 0 -135.344 -30.044 -250 -165.633 V 262 h 500 V 15.867 C 405.344 180.456 280.85 180.5 250 180.5 Z" />
                    </svg>
                </span>
            </div>


            <footer id="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-3">
                            <a href="home.html">
                                <img src={logo} alt="Logo" title="Logo" />
                            </a>
                            <div className="footer_inner">
                                <p className="w-90">Shaping Careers Shaping Brands
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-2 mx-auto">
                            <h5>Usefull Links</h5>
                            <div className="footer_inner">
                                <ul className="list-unstyled">
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="/services">Services</a></li>
                                    <li><a href="/faq">FAQ's </a></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <h5>Contact Us</h5>
                            <div className="footer_inner">
                                <div className="d-flex media">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    <div className="media-body"><p> <span className="f_rubik">145-B/9, Third Floor,</span> Kishangarh, Vasant Kunj, <br/> New Delhi <span className="f_rubik">110070</span> </p></div>
                                </div>
                                <div className="d-flex media">
                                    <i className="fa-regular fa-envelope"></i>
                                    <div className="media-body"><p>connect@huntnscouts.com</p></div>
                                </div>
                                <div className="d-flex media">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div className="media-body"><p className="f_rubik">+91-1141329664</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <h5>Get In Touch</h5>
                            <div className="footer_inner">
                                <form className="form-inline valid" method="post">
                                    <div className="input-group mb-4">
                                        <input type="email" name="email" className="form-control" id="email" placeholder="E-mail Address"  required />
                                        <button className="btn btn-white" name="submit" type="submit" id="submit">
                                            <i className="fa-regular fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                                <ul className="list-inline">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/HuntnScouts"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li className="list-inline-item"><a href="/"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li className="list-inline-item"><a href="/"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li className="list-inline-item"><a href="/">
                                        <i className="fa-brands fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;