import React from "react";
import logo from "../images/logo/Logo.png"
import { NavLink } from "react-router-dom";

const Header=()=>{
    return(
        <>
			<header id="header">

				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container-fluid">

						<NavLink className="navbar-brand" to="/">
							<img src={logo} className="w-80 main-logo" width="150px" alt="Logo" title="Logo" />
						</NavLink>

						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav navbar-center m-auto">
								<li className="nav-item active">
									<NavLink className="nav-link" to="/">Home </NavLink>
								</li>

								<li className="nav-item drop-down">
									<NavLink className="nav-link" to="/about">About Us</NavLink>
								</li>
								<li className="nav-item drop-down">
									<NavLink className="nav-link" to="/services">Services</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to="/categories">Categories</NavLink>
								</li>

								<li className="nav-item">
									<NavLink className="nav-link" to="/contact">Contact</NavLink>
								</li>
							</ul>

							<ul className="navbar-nav navbar-right">
								<li><NavLink className="btn btn-link me-2" to="/profile">
									<i className="fa-solid fa-upload me-2"></i>Upload Resume
								</NavLink></li>
								<li><NavLink className="btn btn-danger" to="/register">Sign Up</NavLink></li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
        </>
    )
}

export default Header;