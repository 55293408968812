import React from "react";
import imgwomen from "../images/home/women.png"

const Jobportal=(props)=>{
    return(
        <>
        <section className="jobPart">
	<div className="container-fluid">
		<div className="row">
			<div className="col-sm-4">
				<div className="jobImage">
					<div className="image-card">
						<img className="w-100" src={imgwomen} alt="Job" />
					</div>
				</div>
			</div>
			<div className="col-sm-8 pt-md-4 pt-sm-0">
				<h1>{props.titleBlack}</h1>
				<h2 className="text-red">{props.titleYellow}</h2>
				<p className="py-3">{props.desc}</p>
			</div>
		</div>
	</div>
</section>
        </>
    )
}
export default Jobportal;