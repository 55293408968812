import React from "react";
import Jobforyoucard from "./Jobforyoucard";
import imgjobhover from '../images/home/job-hover.png'
import imgjobforyou from "../images/home/job-for-you.png"

const Jobforyou=()=>{
    return(
        <>
            <section className="jobforYou">
                <div className="container-fluid">

                    <div id="jobSlider" className="carousel slide">

                        <div className="controls-top">
                            <h1><a className="btn-floating pre" data-bs-target="#jobSlider" data-bs-slide="prev"><i className="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                                Job For <span className="text-red">You</span>
                                <a className="btn-floating next" data-bs-target="#jobSlider" data-bs-slide="next"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                            </h1>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <Jobforyoucard imgurl1={imgjobforyou} imgurl2={imgjobhover} title="Cardiologist - MD/DNB/DM" state="Haryana" status="Active" redirect="/" />
                                    <Jobforyoucard imgurl1={imgjobforyou} imgurl2={imgjobhover} title="Cardiologist - MD/DNB/DM" state="Haryana" status="" redirect="" />
                                    <Jobforyoucard imgurl1={imgjobforyou} imgurl2={imgjobhover} title="Cardiologist - MD/DNB/DM" state="Haryana" status="" redirect="" />
                                    <Jobforyoucard imgurl1={imgjobforyou} imgurl2={imgjobhover} title="Cardiologist - MD/DNB/DM" state="Haryana" status="" redirect="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Jobforyou;